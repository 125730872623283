<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 5.5C3.34315 5.5 2 6.84315 2 8.5C2 10.1569 3.34315 11.5 5 11.5H6C6.55228 11.5 7 11.9477 7 12.5C7 13.0523 6.55228 13.5 6 13.5H5C2.23858 13.5 0 11.2614 0 8.5C0 5.73858 2.23858 3.5 5 3.5H6C6.55228 3.5 7 3.94772 7 4.5C7 5.05228 6.55228 5.5 6 5.5H5ZM9 4.5C9 3.94772 9.44771 3.5 10 3.5H11C13.7614 3.5 16 5.73858 16 8.5C16 11.2614 13.7614 13.5 11 13.5H10C9.44771 13.5 9 13.0523 9 12.5C9 11.9477 9.44771 11.5 10 11.5H11C12.6569 11.5 14 10.1569 14 8.5C14 6.84315 12.6569 5.5 11 5.5H10C9.44771 5.5 9 5.05228 9 4.5ZM4.5 8.5C4.5 7.94772 4.94772 7.5 5.5 7.5H10.5C11.0523 7.5 11.5 7.94772 11.5 8.5C11.5 9.05228 11.0523 9.5 10.5 9.5H5.5C4.94772 9.5 4.5 9.05228 4.5 8.5Z"
      fill="#868D9C"
    />
  </svg>
</template>
